<template>
    <div id="editasset" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editAsset"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="asset_editAsset"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">

                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                            <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("asset_editAsset") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right" data-cy="EditAssetForm">

                                            <div class="kt-portlet__body">
                                                <div class="kt-section kt-margin-b-0">

                                                    <h3 class="kt-section__title">1. {{ $t("asset_assetInfo") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_assetName") }} *</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                    </div>
                                                                    <input id="editAssetNameInput" type="text" maxlength="80" class="form-control" :placeholder="$t('asset_inputInfoAssetName')" v-model="vName" @keyup="onChangeForm" />
                                                                </div>
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetName") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_assetUnit") }} *</label>
                                                                <!-- <select id="editAssetUnitSelect2" class="form-control kt-select2">
                                                                    To display placeholder
                                                                    <option></option>
                                                                </select> -->
                                                                <div class="unit">
                                                                    <div style="width: 100%" class="form-unit form-column form-column-field">
                                                                        <input id="editUnitInput" type="text" maxlength="80" @click="onShowListUnit()" readonly="readonly" class="select-dropdown form-control cursorPointer" :placeholder="$t('asset_selectUnit')" v-model="unitInputVal"/>
                                                                        <i class="fa fa-angle-down icon-down"></i>
                                                                        <div class="list-asset-unit">
                                                                            <span class="search">
                                                                                <input type="text" class="form-column form-column-field" v-model="searchUnit">
                                                                            </span>
                                                                            <ul>
                                                                                <li v-for="unit in this.filterSiteAssetUnits" :key="unit.id" @click="onSelectedUnit(unit)" :class="vUnit && vUnit.id == unit.id && 'unit-selected'">
                                                                                    <span v-if="unit.isDefault">{{$t(unit.name)}}</span>
                                                                                    <div v-else style="display: flex; justify-content: space-between; align-items: center;">
                                                                                        <div>{{unit.name}}</div>
                                                                                        <div>
                                                                                            <button type="button" @click="onEditUnit(unit)" class="btn btn-outline-brand btn-sm btn-icon border-0 mr-2" style="height: 1.9rem; width: 1.9rem"><i class="la la-pencil"></i></button>
                                                                                            <button type="button" @click="onDeleteUnit(unit.id)" class="btn btn-outline-danger btn-sm btn-icon border-0" style="height: 1.9rem; width: 1.9rem"><i class="la la-trash"></i></button>    
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div @click="onShowAddNewUnit()" class="btn btn-brand btn-bold btn-md btn-icon-h kt-margin-l-10 cursorPointer">
                                                                        <span style="font-size: 0.9rem;">
                                                                            <i class="flaticon2-plus kt-margin-r-5"></i>
                                                                            {{ $t("asset_addNewAssetUnit") }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="!$v.vUnit.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetUnit") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_assetFileBrowser") }}</label>
                                                                <div class="custom-file">
                                                                    <input type="file" @change="handleFileSelected" ref="imageAssetFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectAssetImageInputFile" />
                                                                    <label class="custom-file-label textLeft" id="selectAssetImageInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                                                    <span class="form-text text-muted">{{ $t("site_selectImageHelpText") }}</span>
                                                                </div>
                                                            </div>
                                                            <div v-show="isImageLoaded" class="col-lg-6">
                                                                <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.imageToDisplay + ')' }"></div>
                                                                <div class="kt-margin-b-10 kt-margin-t-10">{{ imageName }}</div>
                                                                <div>
                                                                    <button id="editAssetRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">2. {{ $t("asset_tagHelp") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("tag_tagsList") }}</label>
                                                                <select id="editAssetTagSelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetTag") }}</span>
                                                            </div>
                                                            <div v-if="vShowTagType" class="col-lg-6">
                                                                <div class="kt-option boldBorder">
                                                                    <span class="kt-option__control">
                                                                        <img :class="vTagTypeItem.imgClass" :src="vTagTypeItem.img" style="max-width:100%;" />
                                                                    </span>
                                                                    <span class="kt-option__label" style="margin-left:20px;">
                                                                        <span class="kt-option__head">
                                                                            <span class="kt-option__title tagLabel">{{ vTagTypeItem.label }}</span>
                                                                            <span class="kt-option__focus">{{ vTagTypeItem.ref }}</span>
                                                                        </span>
                                                                        <span class="kt-option__body tagColorTxt">
                                                                            {{ vTagTypeItem.desc }}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="showButtonNewTag()" class="col-lg-6">
                                                                <div>
                                                                    <label><br /></label>
                                                                </div>
                                                                <div>
                                                                    <router-link :to="{ name: 'newTag' }" id="editAsset_createNewTagButton" class="btn btn-brand btn-bold btn-sm btn-icon-h">
                                                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                                                        {{ $t("tag_createNewTag") }}
                                                                    </router-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">3. {{ $t("asset_categoryHelp") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_categoriesList") }} *</label>
                                                                <select id="editAssetCategorySelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select>
                                                                <div v-if="!$v.vCategory.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetCategory") }}</span>
                                                            </div>
                                                            <div class="col-lg-6" v-show="vShowSubcategories">
                                                                <label v-if="selectedCategoryName == 'ASSET_C_PATIENTS' && isFrench">{{ $t("asset_subcategoriesListPatient") }}</label>
                                                                <label v-else>{{ $t("asset_subcategoriesList") }}</label>
                                                                <select id="editAssetSubcategorySelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetSubcategory") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="customFieldBloc" v-show="vShowCustomFields">
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>
                                                        <h3 class="kt-section__title">4. {{ $t("asset_customFieldsInfo") }}</h3>
                                                        <div class="kt-section__body">
                                                            <div id="kt_repeater_customfield">
                                                                <div data-repeater-list="kt_repeater_customfield-list" class="col-lg-12 customFieldListReap">
                                                                    <div data-repeater-item="" class="form-group row align-items-center customFieldItemReap">
                                                                        <div class="col-md-3">
                                                                            <div class="kt-form__group--inline">
                                                                                <div class="kt-form__control">
                                                                                    <input type="text" name="text-name-customfield" class="form-control whiteBackground" disabled="disabled" />
                                                                                    <input type="text" name="text-id-customfield" class="form-control" disabled="disabled" style="display:none;" />
                                                                                    <input type="text" name="text-type-customfield" class="form-control" disabled="disabled" style="display:none;" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="d-md-none kt-margin-b-10"></div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <div class="kt-form__group--inline">
                                                                                <div class="kt-form__control">
                                                                                    <input type="text" name="text-value-customfield" class="form-control" :placeholder="$t('common_enterValue')" style="display:none;" />
                                                                                    <a href=""
                                                                                       target="_blank"
                                                                                       rel="noopener"
                                                                                       style="display:none;"
                                                                                       class="custom-field-url">
                                                                                    </a>
                                                                                    <input type="text" name="text-date-customfield" class="form-control" readonly="" :placeholder="$t('common_selectDate')" style="display:none;" />
                                                                                    <select name="select-enum-customfield" class="form-control kt-select2" style="display:none;">
                                                                                        <option></option>
                                                                                    </select>
                                                                                    <input type="checkbox" name="check-bool-customfield" checked="checked" class="col-md-1" style="display:none;" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="d-md-none kt-margin-b-10"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="vTagId">
                                                    
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                        <h3 v-if="vShowCustomFields" class="kt-section__title">5. {{ $t("asset_assetStatusTitle") }}</h3>

                                                        <h3 v-else class="kt-section__title">4. {{ $t("asset_assetStatusTitle") }}</h3>

                                                        <div class="kt-section__body">
                                                            <div class="form-group validated kt-margin-b-0">
                                                                <div class="row" style=" margin-top: 20px; margin-left: 0px; ">
                                                                    <div>
                                                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                                                            <label>
                                                                                <input type="checkbox" checked="checked" id="editAssetStatusCheckbox" v-model="statusActive" @change="editAssetStatusChange">
                                                                                <span></span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                    <label style=" margin-top: -3px; margin-left: 5px; " class="col-form-label" id="editAssetStatusLabel">{{ statusMessage }}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <button id="editAssetCreateButton" @click="onUpdateButton" type="button" :disabled="$v.$invalid || !isFormDataChanged" class="btn btn-brand kt-margin-r-5">
                                                                {{ $t("common_saveChanges") }}
                                                            </button>
                                                            <button id="editAssetCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";
import SiteTitle from "../site/widgets/sitetitle.vue";

export default {
    data() {
        return {
            vUnit: null,
            oldUnit: null,
            unitInputVal: null,
            vTagId: null,
            vTagTypeItem: {},
            vCategory: null,
            vSubCategoryId: null,
            vName: "",
            vCategoryId: null,
            vShowTagType: false,
            vShowSubcategories: false,
            vShowCustomFields: false,
            image: "",
            imageName: "",
            imageFile: null,
            isImageLoaded: false,
            statusMessage: null,
            statusActive: false,
            isFormDataChanged: false,
            isCustomFieldsLoaded: false,
            basePath: process.env.VUE_APP_BACKEND_URL,
            imageToDisplay: "",
            selectedCategoryName: "",
            isFrench: this.$i18n.locale === "fr" ? true : false,
            customFieldsRepeater: null,
            isHandleEditOrDelete: false,
            searchUnit: "",
            listAssetUnits: []
        };
    },
    created: function() {
        console.log("Component(EditAsset)::created() - called");
        // Get current asset
        this.getAssetById({
            siteId: this.$route.params.siteId,
            assetId: this.$route.params.assetId
        });
        this.getAllAssetUnitsOfSite(this.$route.params.siteId);
    },
    mounted: function() {
        console.log("Component(EditAsset)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(EditAsset)::destroyed() - called");
        this.resetAssetsState();
        this.resetCategoriesState();
        this.resetTagsState();
        this.resetAssetUnitsState();
    },
    validations: {
        vName: {
            required,
            minLen: minLength(2)
        },
        vUnit: {
            required
        },
        vCategory: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentAsset: function(asset) {
            console.log("Component(EditAsset)::watch(currentAsset) - called with ", asset);
            if (asset) {
                // Initialize asset part1
                this.vName = asset.name;
                this.statusActive = asset.isEnabled;
                this.editAssetStatusChange();
                if (asset.imageName) {
                    this.imageName = asset.imageName;
                }
                if (asset.image) {
                    const assetImage = asset.image;
                    if (assetImage.includes("data:", 0) && assetImage.includes("base64")) {
                        // Old image (not yet uploaded)
                        this.imageToDisplay = assetImage;
                        this.image = assetImage;
                    } else {
                        this.imageToDisplay = commonVueHelper.convertImageLinkToBase64(this.basePath + assetImage);
                        this.image = commonVueHelper.convertImageLinkToBase64(this.basePath + assetImage);
                    }
                    this.isImageLoaded = true;
                } else {
                    this.isImageLoaded = false;
                }
                this.vUnit = asset.assetUnit;
                this.oldUnit = asset.assetUnit;
                this.unitInputVal = asset.assetUnit.isDefault ? i18n.t(asset.assetUnit.name) : asset.assetUnit.name;
                // this.initUnitSelect2();
                // Get list of tags (including freeTags)
                this.getSiteTags(this.$route.params.siteId);
                // Get site categories
                this.getSiteAssetCategories(this.$route.params.siteId);
            }
        },

        siteAssetCategories: function(siteCategories) {
            console.log("Component(EditAsset)::watch(siteAssetCategories) - called with ", siteCategories);
            if (siteCategories) {
                if (this.currentAsset && this.currentAsset.assetcategory && this.currentAsset.assetcategory.id) {
                    this.initCategorySelect2(this.currentAsset.assetcategory.id);
                } else {
                    this.initCategorySelect2(null);
                }
            }
        },

        freeTags: function(tags) {
            console.log("Component(EditAsset)::watch(freeTags) - called with ", tags);
            // Initialize tag select2
            this.initTagSelect2();
        },

        vCategoryId: function(index) {
            console.log("Component(EditAsset)::watch(vCategoryId) - called with ", index);
            if (index === null) {
                return;
            }
            // Initialize the subcategories and customfields
            let subcategories = [];
            this.vShowSubcategories = this.vShowCustomFields = false;
            let customFields = [];
            if (this.siteAssetCategoriesById[this.vCategoryId]) {
                if (this.siteAssetCategoriesById[this.vCategoryId].subcategories) {
                    subcategories = this.siteAssetCategoriesById[this.vCategoryId].subcategories;
                    this.initSubcategorySelect2(subcategories);
                    this.vShowSubcategories = subcategories.length > 0 ? true : false;
                }
                if (this.siteAssetCategoriesById[this.vCategoryId].customFields) {
                    customFields = this.siteAssetCategoriesById[this.vCategoryId].customFields;
                    this.initCustomFieldRepeater(customFields);
                    this.vShowCustomFields = customFields.length > 0 ? true : false;
                }
            } else {
                console.log("No siteAssetCategoriesById for vCategoryId " + this.vCategoryId);
            }
        },

        user: function(user) {
            console.log("Component(EditAsset)::watch(user) - called with ", user);
            if (user) {
                // In case of language change
                // Translate icon file browser
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
                // Translate unit select2
                // this.updateUnitSelect2();
                // Translate tag select2
                this.updateTagSelect2();
                // Translate categories and subcategories select2
                this.updateCategorySelect2();

                this.unitInputVal = this.vUnit ?
                    this.vUnit.isDefault ?
                        i18n.t(this.vUnit.name) :
                        this.vUnit.name
                    : "";
            }
        },

        assetUnits: function(assetUnits) {
            console.log("Component(EditAsset)::watch(assetUnits) - called with ", assetUnits);
            let newAssetUnit = assetUnits.find(unit => {return unit.isNewCreated});

            if (!newAssetUnit) {
                newAssetUnit = assetUnits.find(unit => { return this.vUnit && unit.id ===  this.vUnit.id });
            }

            if (newAssetUnit) {
                this.vUnit = newAssetUnit;

                if (newAssetUnit.isDefault) {
                    this.unitInputVal = i18n.t(newAssetUnit.name);
                } else {
                    this.unitInputVal = newAssetUnit.name;
                }
            }
        },
        vUnit: function(unit) {
            if (unit.name !== this.oldUnit.name) {
                this.onChangeForm();
            }
        }
    },
    computed: {
        ...mapGetters(["currentAsset", "freeTags", "siteAssetCategories", "siteAssetCategoriesById", "currentSite", "user", "assetUnits"]),
        filterSiteAssetUnits() {
            return this.assetUnits.filter(assetUnit => {
                let unitName = assetUnit.isDefault ? i18n.t(assetUnit.name) : assetUnit.name;
                return unitName.toLowerCase().indexOf(this.searchUnit.toLowerCase()) != -1;
            });
        }
    },
    methods: {
        ...mapActions([
            "getAssetById",
            "resetAssetsState",
            "getSiteTags",
            "resetTagsState",
            "getSiteAssetCategories",
            "resetCategoriesState",
            "updateAsset",
            "uploadAssetImage",
            "getAllAssetUnitsOfSite",
            "deleteAssetUnitById",
            "resetAssetUnitsState",
            "showAssetUnitModal"
        ]),

        onShowAddNewUnit() {
            console.log("Component(EditAsset)::onShowAddNewUnit() - called");
            this.showAssetUnitModal({type: "CREATE"});
        },

        onSelectedUnit(unit) {
            if(!this.isHandleEditOrDelete) {
                this.vUnit = unit;
                if (unit.isDefault) {
                    this.unitInputVal = i18n.t(unit.name);
                } else {
                    this.unitInputVal = unit.name;
                }
            }
            $(".list-asset-unit").hide();
        },

        onShowListUnit() {
            $("#editUnitInput").on('keydown keypress', function (e) {
                e.preventDefault();
            });
            if ($(".list-asset-unit").css('display') === 'none') {
                $(".list-asset-unit").show();
                this.registerEventUnit();
            } else {
                $(".list-asset-unit").hide();
            }
            if($(".unit-selected")[0]) {
                $(".list-asset-unit ul").animate({
                    scrollTop: $(".unit-selected")[0].offsetTop - 150
                }, 0);
            }
        },

        registerEventUnit() {
            if ($(".list-asset-unit").css('display') === 'block') {
                $("body").click(function (e) {
                    if (
                        (!$(".list-asset-unit").is(e.target) && $(".list-asset-unit").has(e.target).length === 0) &&
                        (!$("#editUnitInput").is(e.target) && $("#editUnitInput").has(e.target).length === 0)
                    ) {
                        $(".list-asset-unit").hide();
                    }
                });
            }
        },

        onEditUnit(unit) {
            this.isHandleEditOrDelete = true;
            this.showAssetUnitModal({type: "UPDATE", data: unit });
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 100);
        },

        onDeleteUnit(unitId) {
            this.isHandleEditOrDelete = true;
            this.deleteAssetUnitById({ siteId: this.$route.params.siteId, unitId: unitId });

            if (this.vUnit && unitId === this.vUnit.id) {
                if (unitId !== this.currentAsset.assetUnit.id) {
                }
                this.vUnit = this.currentAsset.assetUnit;
                this.unitInputVal = this.currentAsset.assetUnit.isDefault ? i18n.t(this.currentAsset.assetUnit.name) : this.currentAsset.assetUnit.name;
            }
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 50);
        },

        getTagDataGroupSelect2() {
            let dataGroup = {};
            let tagTypeName, tagTypeLabel;
            if (this.currentAsset && this.currentAsset.tag && this.currentAsset.tag.id) {
                tagTypeName = commonVueHelper.getPropFromTagType("name", this.currentAsset.tag.tagType); // Can be "ASSET", "CARD", "UNIVERSAL" in tagtype table of CTC database
                tagTypeLabel = commonVueHelper.getPropFromTagType("label", this.currentAsset.tag.tagType);
                // Add the current asset tag to freeTags dataGroup
                dataGroup[tagTypeName] = {
                    text: tagTypeLabel,
                    children: []
                };
                dataGroup[tagTypeName].children.push({
                    id: this.currentAsset.tag.id,
                    text: this.currentAsset.tag.serialNumber
                });
            }
            if (this.freeTags) {
                // Add freeTags to dataGroup select2
                for (let i = 0; i < this.freeTags.length; i++) {
                    tagTypeName = commonVueHelper.getPropFromTagType("name", this.freeTags[i].tagType); // Can be "ASSET", "CARD", "UNIVERSAL" in tagtype table of CTC database
                    tagTypeLabel = commonVueHelper.getPropFromTagType("label", this.freeTags[i].tagType);
                    // Group free tags by tag type
                    if (!dataGroup[tagTypeName]) {
                        dataGroup[tagTypeName] = {};
                    }
                    dataGroup[tagTypeName].text = tagTypeLabel;

                    // Add child option by {id, macAdress} in group
                    if (!dataGroup[tagTypeName].children) {
                        dataGroup[tagTypeName].children = [];
                    }
                    let dataOpt = {
                        id: this.freeTags[i].id,
                        text: this.freeTags[i].serialNumber
                    };
                    dataGroup[tagTypeName].children.push(dataOpt);
                }
            }
            // 1. Order group by text (and OTHER key at the end)
            let keysSorted = Object.keys(dataGroup).sort(function(a, b) {
                if (a === "OTHER") {
                    // OTHER to set at the end of keySorted
                    return 1; // a is greater than b
                }
                if (b === "OTHER") {
                    // OTHER to set at the end of keySorted
                    return -1; // a is less than b
                }
                if (dataGroup[a].text < dataGroup[b].text) {
                    return -1; // a is less than b
                }
                return 1; // a is greater than b
            });
            // 2. Order group type by children text
            let dataGroupListSelect2 = [];
            for (let i = 0; i < keysSorted.length; i++) {
                let tagTypeName = keysSorted[i];
                if (dataGroup[tagTypeName]) {
                    // Ascending sort order of datagroup children (by MacAddress)
                    dataGroup[tagTypeName].children = _.orderBy(dataGroup[tagTypeName].children, [child => child.text.toLowerCase()], ["asc"]);
                    dataGroupListSelect2.push(dataGroup[tagTypeName]);
                }
            }
            return dataGroupListSelect2;
        },

        initTagSelect2() {
            console.log("Component(EditAsset)::initTagSelect2() - called");
            var mySelf = this;
            // Init Select2 data of tags
            const dataGroupListSelect2 = this.getTagDataGroupSelect2();
            this.vShowTagType = false;
            let tagSelector = $("#editAssetTagSelect2");
            commonVueHelper.destroySelect2(tagSelector);
            tagSelector
                .select2({
                    allowClear: true,
                    placeholder: i18n.t("asset_selectTag"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataGroupListSelect2
                })
                // emit event on change.
                .on("change", function(event) {
                    mySelf.vTagId = $(this).val();
                    // Is it a initial initialisation
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        mySelf.onChangeForm();
                    }
                    mySelf.vShowTagType = false;
                    // Get the tag type item of the selected tag
                    mySelf.vTagTypeItem = {};
                    if (mySelf.vTagId) {
                        // Get the tag type information to display
                        if (mySelf.currentAsset.tag && mySelf.vTagId === mySelf.currentAsset.tag.id) {
                            // It's the
                            mySelf.vTagTypeItem = commonVueHelper.getTagTypeItem(mySelf.currentAsset.tag.tagType);
                        } else {
                            for (let i = 0; i < mySelf.freeTags.length; i++) {
                                if (mySelf.vTagId === mySelf.freeTags[i].id) {
                                    mySelf.vTagTypeItem = commonVueHelper.getTagTypeItem(mySelf.freeTags[i].tagType);
                                    break;
                                }
                            }
                        }
                    }
                    // Show the tag info if the selected tag type is defined (and not other)
                    if (mySelf.vTagTypeItem.name) {
                        mySelf.vShowTagType = true;
                    }
                });
            // Init the selected tag to the currentAsset tag value
            if (this.currentAsset && this.currentAsset.tag) {
                tagSelector.val(this.currentAsset.tag.id);
            } else {
                // No tag
                tagSelector.val(null);
            }
            tagSelector.trigger({ type: "change", params: { initialInit: true } });
        },

        updateTagSelect2() {
            // Get data of tag select2
            const dataGroupListSelect2 = this.getTagDataGroupSelect2();
            // Save selected tag
            const currentValue = $("#editAssetTagSelect2").val();
            // Update tag select2
            $("#editAssetTagSelect2")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectTag"),
                    data: dataGroupListSelect2
                })
                .val(currentValue)
                .trigger("change");
        },

        initUnitSelect2() {
            console.log("Component(EditAsset)::initUnitSelect2() - called");
            var mySelf = this;
            // Init unit Select2
            let unitSelector = $("#editAssetUnitSelect2");
            commonVueHelper.destroySelect2(unitSelector);
            unitSelector
                .select2({
                    placeholder: i18n.t("asset_selectUnit"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: commonVueHelper.getUnitsData()
                })
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    mySelf.vUnit = $(this).val();
                    // Is it a initial initialisation
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        mySelf.onChangeForm();
                    }
                });
            // Init the selected unit to the currentAsset unit value
            unitSelector.val(this.currentAsset.unit);
            unitSelector.trigger({ type: "change", params: { initialInit: true } });
        },

        updateUnitSelect2() {
            const currentValue = this.vUnit;
            $("#editAssetUnitSelect2")
                .empty()
                .select2( {
                    placeholder: i18n.t("asset_selectUnit"),
                    data: commonVueHelper.getUnitsData()
                })
                .val(currentValue)
                .trigger("change");
        },

        getDataCategorySelect2() {
            let dataCategorySelect2 = [];
            for (let i = 0; i < this.siteAssetCategories.length; i++) {
                let categoryName = this.siteAssetCategories[i].name;
                let isDefault = this.siteAssetCategories[i].isDefault;
                let image = this.siteAssetCategories[i].iconImg;
                let categoryNameTranslated;
                if (this.siteAssetCategories[i].isDefault) {
                    categoryNameTranslated = i18n.t(categoryName);
                } else {
                    categoryNameTranslated = categoryName;
                }
                dataCategorySelect2.push({ id: this.siteAssetCategories[i].id, text: categoryNameTranslated, categoryNameTranslated, categoryName: categoryName, isDefault: isDefault, image: image });
            }
            // Ascending sort order Select2 data by text
            dataCategorySelect2 = _.orderBy(dataCategorySelect2, [category => category.text.toLowerCase()], ["asc"]);

            return dataCategorySelect2;
        },

        formatCategory(categoryItem) {
            if (!categoryItem.id) return categoryItem.text;
            let formatCat;
            if (categoryItem.isDefault) {
                formatCat = "<img class='flag' style='width:30px;margin-right:5px' src='/assets/categories/" + categoryItem.categoryName + "/Icon.png'/>";
            } else {
                formatCat = "<img class='iconPreviewCustomCat' style='background-image:url(" + categoryItem.image + ");margin-right:5px' />";
            }
            formatCat += "<span>" + categoryItem.text + "</span>";
            return $(formatCat);
        },

        initCategorySelect2(currentCategoryId) {
            console.log("Component(EditAsset)::initCategorySelect2() - called with currentCategoryId " + currentCategoryId);
            var mySelf = this;
            // Init Select2 data of categories
            const dataCategorySelect2 = this.getDataCategorySelect2();
            // Init categories Select2
            const categorySelector = $("#editAssetCategorySelect2");
            commonVueHelper.destroySelect2(categorySelector);
            categorySelector
                .select2({
                    placeholder: i18n.t("asset_selectCategory"),
                    width: "100%",
                    data: dataCategorySelect2,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    escapeMarkup: function(m) {
                        return m;
                    },
                    templateResult: mySelf.formatCategory,
                    templateSelection: mySelf.formatCategory
                })
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    // Store category index to retrieve associated subcategory
                    mySelf.vCategoryId = $(this).val();
                    if (mySelf.vCategoryId) {
                        mySelf.vCategory = mySelf.vCategoryId;
                        // Store selected asset category name in local variable
                        const assetCategory = mySelf.$store.getters.getAssetCategoryById(mySelf.vCategoryId);
                        mySelf.selectedCategoryName = assetCategory.name;
                    } else {
                        mySelf.vCategory = null;
                    }
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        mySelf.onChangeForm();
                    }
                });

            // Init the selected category to the currentAsset unit value
            categorySelector.val(currentCategoryId);

            // Store selected asset category name in local variable
            const assetCategory = this.$store.getters.getAssetCategoryById(currentCategoryId);
            mySelf.selectedCategoryName = assetCategory.name;

            categorySelector.trigger({ type: "change", params: { initialInit: true } });
        },

        updateCategorySelect2() {
            console.log("Component(EditAsset)::updateCategorySelect2() - called");
            var mySelf = this;
            // Init Select2 data of categories
            const dataCategorySelect2 = this.getDataCategorySelect2();
            // Save selected category
            const currentValue = $("#editAssetCategorySelect2").val();
            // Update categories list
            $("#editAssetCategorySelect2")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectCategory"),
                    data: dataCategorySelect2,
                    templateResult: mySelf.formatCategory,
                    templateSelection: mySelf.formatCategory
                })
                .val(currentValue)
                .trigger("change");
            // Update attached subcategories
            if (currentValue) {
                const subcategories = this.siteAssetCategoriesById[currentValue].subcategories;
                if (subcategories) {
                    this.updateSubcategorySelect2(subcategories);
                }
            }
        },

        initSubcategorySelect2(subcategories) {
            console.log("Component(EditAsset)::initSubcategorySelect2() - called");
            var mySelf = this;
            // Init Select2 data of subcategories
            let dataSubcategorySelect2 = $.map(subcategories, function(subcategory) {
                let subcategoryName = subcategory.name;
                if (subcategory.isDefault && subcategory.name) {
                    subcategoryName = i18n.t(subcategory.name);
                }
                subcategory.text = subcategory.text || subcategoryName;
                return subcategory;
            });
            // Ascending sort order Select2 data by text
            dataSubcategorySelect2 = _.orderBy(dataSubcategorySelect2, [subcategory => subcategory.text.toLowerCase()], ["asc"]);
            // Init subcategories Select2
            let subcategorySelector = $("#editAssetSubcategorySelect2");
            commonVueHelper.destroySelect2(subcategorySelector);
            subcategorySelector
                .select2({
                    placeholder: i18n.t("asset_selectSubcategory"),
                    data: dataSubcategorySelect2,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    width: "100%"
                })
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    mySelf.vSubCategoryId = $(this).val();
                    // Is it a new subcategory for the current asset
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        mySelf.onChangeForm();
                    }
                });

            // Init the selected subcategory to the currentAsset subcategory value
            if (this.currentAsset.assetsubcategory && this.currentAsset.assetsubcategory.id) {
                subcategorySelector.val(this.currentAsset.assetsubcategory.id); // Select the option with the subcategory value
            } else {
                subcategorySelector.val(null); // No value selected
            }
            subcategorySelector.trigger({ type: "change", params: { initialInit: true } });
        },

        updateSubcategorySelect2(subcategories) {
            console.log("Component(EditAsset)::updateSubcategorySelect2() - called");
            // Init Select2 data of subcategories
            let dataSubcategorySelect2 = $.map(subcategories, function(subcategory) {
                if (subcategory.isDefault && subcategory.name) {
                    subcategory.text = i18n.t(subcategory.name) || subcategory.text;
                } else {
                    subcategory.text = subcategory.text || subcategory.name;
                }
                return subcategory;
            });
            // Ascending sort order Select2 data by text
            dataSubcategorySelect2 = _.orderBy(dataSubcategorySelect2, [subcategory => subcategory.text.toLowerCase()], ["asc"]);
            // Save selected subcategory
            const currentValue = $("#editAssetSubcategorySelect2").val();
            // Update subcategories list
            $("#editAssetSubcategorySelect2")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectSubcategory"),
                    data: dataSubcategorySelect2
                })
                .val(currentValue)
                .trigger("change");
        },

        initRepeaterItem() {
            let str_enterValue = i18n.t("common_enterValues");
            let str_selectDate = i18n.t("common_selectDate");
            let htmlToInsert = '\
                <div data-repeater-item="" class="form-group row align-items-center customFieldItemReap">\
                    <div class="col-md-3">\
                        <div class="kt-form__group--inline">\
                            <div class="kt-form__control">\
                                <input type="text" name="text-name-customfield" class="form-control" disabled="disabled" style="background:white;border:none;padding-left:0px"/>\
                                <input type="text" name="text-id-customfield" class="form-control" disabled="disabled" style="display:none;" />\
                                <input type="text" name="text-type-customfield" class="form-control" disabled="disabled" style="display:none;" />\
                            </div>\
                        </div>\
                        <div class="d-md-none kt-margin-b-10"/>\
                    </div>\
                    <div class="col-md-3">\
                        <div class="kt-form__group--inline">\
                            <div class="kt-form__control">\
                                <input type="text" name="text-value-customfield" class="form-control" :placeholder="'+str_enterValue+'" style="display:none;" />\
                                <a href="" target="_blank" rel="noopener" display:none;" class="custom-field-url"></a>\
                                <input type="text" name="text-date-customfield" class="form-control" readonly="" :placeholder="'+str_selectDate+'" style="display:none;" />\
                                <select name="select-enum-customfield" class="form-control kt-select2" style="display:none;">\
                                    <option/>\
                                </select>\
                                <input type="checkbox" name="check-bool-customfield" checked="checked" class="col-md-1" style="display:none;" />\
                            </div>\
                        </div>\
                        <div class="d-md-none kt-margin-b-10"/>\
                    </div>\
                </div>';
            $(".customFieldItemReap").remove();
            $(".customFieldListReap").append(htmlToInsert);
        },

        initCustomFieldRepeater(customFields) {
            console.log("Component(EditAsset)::initCustomFieldRepeater() - called");
            var mySelf = this;
            mySelf.isCustomFieldsLoaded = false;
            // Set the values of tcustom fields by id
            let customFieldsById = {};
            for (let n = 0; n < customFields.length; n++) {
                let item = customFields[n];
                customFieldsById[item.id] = item;
            }
            // Hide all previous input repeaters (for which name end with [text-xxx-customfield])
            $("#kt_repeater_customfield")
                .find("input[name$='" + "[text-value-customfield]" + "']")
                .hide();
            $("#kt_repeater_customfield")
                .find("input[name$='" + "[text-date-customfield]" + "']")
                .hide();
            $("#kt_repeater_customfield")
                .find("input[name*='" + "[check-bool-customfield]" + "']")
                .hide();
            let enumSelectors = $("#kt_repeater_customfield").find("select[name$='" + "[select-enum-customfield]" + "']");
            for (let k = 0; k < enumSelectors.length; k++) {
                commonVueHelper.destroySelect2($(enumSelectors[k]));
                $(enumSelectors[k]).hide();
            }

            if (this.customFieldsRepeater) {
                this.initRepeaterItem();
                this.customFieldsRepeater = null;
            }

            // Setup the repeater for custom fields
            this.customFieldsRepeater = $("#kt_repeater_customfield")
                .repeater({
                    initEmpty: false,
                    show: function() {
                        // Show the repeater item according to its custom field type type
                        let currentRepeaterVal = $(this).repeaterVal();
                        let id = currentRepeaterVal["kt_repeater_customfield-list"][0]["text-id-customfield"];
                        if (id) {
                            // Init jquery selector of each custom field
                            let selector = null;
                            switch (customFieldsById[id].typec) {
                                case "Date":
                                    selector = $(this).find("input[name$='" + "[text-date-customfield]" + "']");
                                    commonVueHelper.initDatePicker(selector, customFieldsById[id].value);
                                    break;
                                case "Enum":
                                    selector = $(this).find("select[name$='" + "[select-enum-customfield]" + "']");
                                    commonVueHelper.initSelect2CustomField(selector, customFieldsById[id]);
                                    break;
                                case "Bool":
                                    selector = $(this).find("input[name*='" + "[check-bool-customfield]" + "']");
                                    commonVueHelper.setCheckBoxVal(selector, customFieldsById[id].value);
                                    break;
                                case "URL":
                                    selector = $(this).find("a[class=custom-field-url]");
                                    commonVueHelper.setURLVal(selector, customFieldsById[id].staticValue);
                                    break;
                                default:
                                    selector = $(this).find("input[name$='" + "[text-value-customfield]" + "']");
                            }
                            selector.show();
                            // Display the custom field
                            $(this).slideDown();
                        } else {
                            // It's the last custom field (empty custom field)
                            mySelf.isCustomFieldsLoaded = true;
                        }
                    }
                })
                .on("change", () => {
                    if (mySelf.isCustomFieldsLoaded) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        mySelf.onChangeForm();
                    }
                });
            // Set the values of the inputs as a formatted object
            let textInputCustomFieldList = [];
            for (let i = 0; i < customFields.length; i++) {
                // Init repeater labels
                let customfieldName = customFields[i].name;
                if (customFields[i].isDefault && customfieldName) {
                    customfieldName = i18n.t(customfieldName);
                }
                let repeaterVal = { "text-name-customfield": customfieldName, "text-id-customfield": customFields[i].id, "text-type-customfield": customFields[i].typec };
                // Init repeater values with the currentAsset values at the beginning
                if (this.currentAsset.customfields) {
                    // Search the custom field value of the current asset
                    let currentValue = null;
                    for (let k = 0; k < this.currentAsset.customfields.length; k++) {
                        if (customFields[i].id === this.currentAsset.customfields[k].id) {
                            if (this.currentAsset.customfields[k].isStatic) {
                                currentValue = this.currentAsset.customfields[k].staticValue;
                            } else {
                                currentValue = this.currentAsset.customfields[k].value;
                            }
                            break;
                        }
                    }
                    if (currentValue !== null) {
                        switch (customFields[i].typec) {
                            case "Date":
                                repeaterVal["text-date-customfield"] = currentValue;
                                break;
                            case "Enum":
                                repeaterVal["select-enum-customfield"] = currentValue;
                                break;
                            case "Bool":
                                if (currentValue === "true") {
                                    repeaterVal["check-bool-customfield"] = ["on"];
                                }
                                break;
                            case "String":
                                repeaterVal["text-value-customfield"] = currentValue;
                                break;
                            case "URL":
                                repeaterVal["custom-field-url"] = currentValue;
                                break;
                        }
                        customFieldsById[customFields[i].id].value = currentValue;
                    }
                }
                textInputCustomFieldList.push(repeaterVal);
            }
            // Add empty custom field used to know the ended initialisation of all custom fields (with boolean isCustomFieldsLoaded)
            textInputCustomFieldList.push({ "text-name-customfield": null, "text-id-customfield": null });
            // Set customfields values
            this.customFieldsRepeater.setList(textInputCustomFieldList);
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(EditAsset)::handleFileSelected() - called");
            if (this.$refs.imageAssetFile.files.length > 0) {
                let imgSelectedFile = this.$refs.imageAssetFile.files[0];
                if (this.$refs.imageAssetFile.files[0].size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.image = "";
                    this.imageToDisplay = "";
                    this.imageName = "";
                    this.isImageLoaded = false;
                    this.imageFile = null;
                } else {
                    this.imageFile = imgSelectedFile;
                    this.imageName = this.imageFile.name;
                    var reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = () => {
                        this.image = reader.result;
                        this.imageToDisplay = this.image;
                        this.isImageLoaded = true;
                        this.onChangeForm();
                    };
                }
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            console.log("Component(EditAsset)::onRemoveImage() - called");
            this.image = "";
            this.imageName = "";
            this.imageToDisplay = "";
            this.imageFile = null;
            this.isImageLoaded = false;
            // Force clear of input file to be able to select the same image again
            $("#selectAssetImageInputFile").val(null);
            //  Replaceremoved file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
            // Set change data
            this.onChangeForm();
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(EditAsset)::onCancel() - called");
            this.$router.push({ name: "assets", params: { fromAction: "cancelButton" } });
        },

        // Trigger when user change the active checkbox
        editAssetStatusChange() {
            if (this.statusActive) {
                this.statusMessage = i18n.t("asset_assetStatusActive");
            } else {
                this.statusMessage = i18n.t("asset_assetStatusInactive");
            }
            if (this.statusActive != this.currentAsset.isEnabled) {
                this.onChangeForm();
            }
        },

        // Function called when user click on the "Create site" button
        onUpdateButton() {
            console.log("Component(EditAsset)::onUpdateButton() - called");

            // Initialize data of the asset create
            let assetData = {
                siteId: this.$route.params.siteId,
                assetId: this.$route.params.assetId,
                name: this.vName,
                assetUnitId: this.vUnit.id,
                image: this.image,
                imageName: this.imageName
            };

            if (this.vTagId) {
                assetData.tagId = this.vTagId;
                assetData.isEnabled = this.statusActive;
            } else {
                assetData.tagId = null;
                assetData.isEnabled = false;
            }

            if (this.vCategoryId) {
                assetData.assetCategoryId = this.vCategoryId;
            }

            if (this.vSubCategoryId) {
                assetData.assetSubcategoryId = this.vSubCategoryId;
            }

            if (this.vShowCustomFields) {
                // Get the values of the custom fields repeater
                let repeaterCustomFieldsVal = $("#kt_repeater_customfield").repeaterVal();
                if (repeaterCustomFieldsVal && repeaterCustomFieldsVal["kt_repeater_customfield-list"] && repeaterCustomFieldsVal["kt_repeater_customfield-list"].length > 0) {
                    assetData.customfields = [];
                    for (let i = 0; i < repeaterCustomFieldsVal["kt_repeater_customfield-list"].length; i++) {
                        let repeaterItem = repeaterCustomFieldsVal["kt_repeater_customfield-list"][i];
                        let repeaterValue = "";
                        // Get values according to custom field type
                        switch (repeaterItem["text-type-customfield"]) {
                            case "String":
                                repeaterValue = repeaterItem["text-value-customfield"];
                                break;
                            case "URL":
                                repeaterValue = repeaterItem["custom-field-url"];
                                break;
                            case "Enum":
                                repeaterValue = repeaterItem["select-enum-customfield"];
                                break;
                            case "Date":
                                if (repeaterItem["text-date-customfield"]) {
                                    let selector = $("#kt_repeater_customfield").find("input[name='kt_repeater_customfield-list[" + i + "][text-date-customfield]" + "']");
                                    repeaterValue = commonVueHelper.getDatePickerISOString(selector);
                                }
                                break;
                            case "Bool":
                                repeaterValue = "false";
                                if (repeaterItem["check-bool-customfield"]) {
                                    if (repeaterItem["check-bool-customfield"].length === 1) {
                                        repeaterValue = "true";
                                    }
                                }
                                break;
                        }
                        if (repeaterItem["text-id-customfield"]) {
                            assetData.customfields.push({ id: repeaterItem["text-id-customfield"], value: repeaterValue });
                        }
                    }
                }
            } else {
                // Select category has no custom fields
                assetData.customfields = [];
            }

            // Update new asset in the backend directly as there is no image for the asset
            this.updateAsset(assetData);
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        },

        showButtonNewTag() {
            // No tag selected
            if (!this.vTagId) {
                // No free tags
                if (!this.freeTags) {
                    return true;
                }
                if (this.freeTags.length === 0) {
                    return true;
                }
            }
            return false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },
    filters: {},

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditAsset", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.textLeft {
    text-align: left !important;
}
.imagePreview {
    height: 108px;
    width: 160px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #ebedf2;
}
.whiteBackground {
    background: white;
    border: none;
    padding-left: 0px;
}
.customFieldListReap {
    margin-top: 30px;
    margin-left: -10px;
}
.boldBorder {
    border: 1px solid #d2d5dc;
}
.tagColorTxt {
    color: #2d2929 !important;
}
.unit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}
.form-unit {
    position: relative;
}
.icon-down {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #b3b7c0;
}
.list-asset-unit {
    width: 100%;
    position: absolute;
    top: 100%;
    background: white;
    border: 1px solid #d2d5dc;
    z-index: 89;
    cursor: pointer;
    display: none;
}
.list-asset-unit span.search {
    padding: 15px;
    display: block;
}
.list-asset-unit span.search input {
    width: 100%;
    border: 1px solid #ced0d6;
    border-radius: 4px;
    height: 30px;
    outline: none !important;
}
.list-asset-unit ul {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 0px;
}
.list-asset-unit ul li {
    padding: 5px 15px;
}
.list-asset-unit ul li:hover {
    background: #E1E1E4;
}
.unit-selected {
    background: #E1E1E4;
}
</style>
